import React from "react"
import Helmet from "react-helmet"
import { graphql } from 'gatsby'
import Layout from "../components/layout"

import BackgroundImage from 'gatsby-background-image'

const ContactPage = ({data: {site, cover}}) => {
  return (
    <Layout>
      <Helmet>
        <title>Contact — {site.siteMetadata.title}</title>
        <meta name="description" content={"Contact page of " + site.siteMetadata.description} />
      </Helmet>
      <div className="two-grids -contact">
        <BackgroundImage className="post-thumbnail" fluid={cover.childImageSharp.fluid} style={{marginBottom: 0}}>
          <h1 className="post-title">Get in Touch</h1>
          <p>Let's kick start your life in Cyprus &rarr;</p>
        </BackgroundImage>
        <div>
          <form className="form-container" name="contactForm" method="post" action="https://api.web3forms.com/submit">
            <input type="hidden" name="form-name" value="contactForm" />
            <input type="hidden" name="subject" value="New Submission from Study.cy" />
            <input type="hidden" name="access_key" value="56634daa-0981-4f01-9c7f-114f92939a5b"/>
            <div>
              <label htmlFor="name">Name</label>
              <input type="text" name="name"/>
            </div>
            <div>
              <label htmlFor="email">Email</label>
              <input type="email" name="email"/>
            </div>
            <div>
              <label htmlFor="message">Message</label>
              <textarea name="message"></textarea>
            </div>
            <div style={{display: "flex", justifyContent: "flex-end"}}>
              <input type="submit" className="button -primary" style={{marginRight: 0, cursor: 'pointer'}} />
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}
export default ContactPage
export const pageQuery = graphql`
  query ContactPageQuery{
    site {
      siteMetadata {
        title
        description
      }
    }
    cover: file(relativePath: {eq: "assets/contact.jpg"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
